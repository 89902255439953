import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import About from './About';
import ProjectList from './ProjectList';
import Research from './Research';
import Now from './Now'; // Ensure file name matches case (Now.js)

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBook, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

library.add(faBook, faGraduationCap, faGithub, faLinkedin);

function App() {
  return (
    <Router>
      <div className="App">
        <header>
          <nav>
            <ul>
              <li><Link to="/">HOME</Link></li>
              <li><Link to="/now">NOW</Link></li>
              <li><a href="https://medium.com/@YazanMashal" target="_blank" rel="noopener noreferrer">BLOG</a></li>
              <li><Link to="/projects">PROJECTS</Link></li>
              <li><a href="https://drive.google.com/file/d/1SXxs3LmhiQcj3Ci0fODxVCW88_KO1uW3/view?usp=sharing" target="_blank" rel="noopener noreferrer">CV</a></li>
              <li><Link to="/research">RESEARCH</Link></li>
            </ul>
          </nav>
        </header>
        <main>
          <Routes>
            <Route path="/" element={<About />} />
            <Route path="/now" element={<Now />} />
            <Route path="/projects" element={<ProjectList />} />
            <Route path="/research" element={<Research />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;