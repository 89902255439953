// Now.js
import React from "react";
import "./Now.css";

const Now = () => {
  return (
    <div className="now-page">
      <h1>Now</h1>
      
      <h2>In the first year of my MSc in Applied Mathematics @ Delft.</h2>

      <p>Thinking about what to do next. Some ideas:</p>
      <ul className="now-list">
        <li>I am currently looking to help co-host the largest AI event in the Netherlands: XAI.</li>
        <li>Looking to do/publish AI projects: AI for energy, AI for self-driving.</li>
        <li>I am reading and working on systemizing the metric for XAI.</li>
      </ul>

      <p>Reading many papers published recently, and summarizing the most important ones on my medium and Twitter.</p>

      <p>I believe that we passed peak data relevance some time ago, and that future models will draw most of their training signal from some kind of reinforcement learning or self-distillation. Look at <a href="https://github.com/deepseek-ai/DeepSeek-R1/blob/main/DeepSeek_R1.pdf" target="_blank" rel="noopener noreferrer">DeepSeek</a></p>

      <h3>Recent posts:</h3>
      <ul className="now-list">
        <li><a href="https://medium.com/@YazanMashal/exploring-the-infinite-the-fascinating-world-of-infinity-in-mathematics-d6e1c1209f4" target="_blank" rel="noopener noreferrer">Exploring the Infinite: The Fascinating World of Infinity in Mathematics</a></li>
      </ul>

      <h3>Papers so far, more soon:</h3>
      <ul className="now-list">
      </ul>
    </div>
  );
};

export default Now;